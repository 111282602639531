<template>
    <div class="location_popover">
        <div class="header">
            <span>图层控制</span>
            <i class="el-icon-close" style="cursor:pointer;" @click="closeLayer"></i>
        </div>
        <div class="content">
            <el-tabs v-model="activeName2" type="card">
                <el-tab-pane
                    v-for="(item,index) in layerData"
                    :key="index"
                    :label="item.label"
                    :name="item.name"
                >
                    <div
                        v-for="(val,ind) in item.layers"
                        :key="ind"
                        class="item"
                        @click="changeShow(index, ind, val)"
                        style="clear:both"
                    >
                        <img v-if="val.isVisible" src="@image/map/mapImg/mapTool/eye_open.png" />
                        <img v-else src="@image/map/mapImg/mapTool/eye_closed.png" />
                        <div>{{val.showName}}</div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
export default {
    name: "control-layers",
    props: {
        basicMap: {
            type: Object,
            default: ""
        },
        currentTool: String,
        opacity: {
            type: Number,
            default: () => {
                return 1;
            }
        }
    },
    data() {
        return {
            activeName2: "1",
            layerControl: [],
            VectortileLayer: null,
            VectortileList: {}, //矢量瓦片图层列表 用来图层控制 关闭矢量瓦片图层时暂存该图层
            layerData: []
        };
    },
    computed: {},
    mounted() {
        setTimeout(() => {
            this.initLayerContral();
        }, 1000);
    },
    methods: {
        closeLayer: function() {
            this.$emit("update:currentTool", "");
        },
        // 图层显隐控制
        changeShow(index, ind, layerObj) {
            // 改变按钮样式
            if (
                this.layerData[index] &&
                this.layerData[index].layers[ind] &&
                this.layerData[index].layers[ind]
            ) {
                this.layerData[index].layers[ind].isVisible = !this.layerData[
                    index
                ].layers[ind].isVisible;
            }
            // 改变图层

            let isVisible = this.layerData[index].layers[ind].isVisible;
            let opacity = isVisible ? this.opacity : 0;

            let show = isVisible ? "visible" : "none";
            let showName = layerObj.showName;
            if (layerObj.size == "image") {
                this.changeImage(showName, opacity);
            } else if (layerObj.size == "Vectortile") {
                // this.changeVectortile(layerObj, show, index, ind, showName);
                this.$emit("changeVectortile", [showName, isVisible]);
            }
        },
        // 改变切片类型图层显影
        changeImage(showName, opacity) {
            this.basicMap.eachLayer(v => {
                let options = v.options || {};
                if (options.showName == showName) {
                    v.setOpacity(opacity);
                }
            });
        },
        // 改变矢量瓦片类型图层显影
        changeVectortile(layerObj, show, index, ind, showName) {
            let _this = this;
            this.basicMap.eachLayer(v => {
                let options = v.options || {};
                if (
                    options.size &&
                    options.size == "Vectortile" &&
                    options.showName == showName
                ) {
                    _this.VectortileList[showName] = v;
                    _this.basicMap.removeLayer(v);
                }
            });
            if (show == "visible") {
                _this.basicMap.addLayer(_this.VectortileList[showName]);
            }
            this.MapMove();
        },
        // 矢量瓦片类型图层后产生位移 需进行地图位移
        MapMove() {
            let currentCenter = this.basicMap.getCenter();
            let currentZoom = this.basicMap.getZoom();
            let lat = currentCenter.lat;
            let lng = currentCenter.lng;
            this.$nextTick(() => {
                this.basicMap.flyTo([lat, lng], currentZoom, {
                    duration: 0.001
                });
            });
        },
        // 获得图层控制数组
        initLayerContral() {
            let typeLists = [];
            this.layerControl = [];
            let tabList = [];
            this.basicMap.eachLayer(layer => {
                if (layer.options.showType) {
                    if (!tabList.includes(layer.options.showType)) {
                        let layerList = {
                            label: layer.options.showType,
                            name: this.layerControl.length + 1 + "",
                            layers: []
                        };
                        tabList.push(layer.options.showType);
                        this.layerControl.push(layerList);
                    }
                    this.layerControl.forEach(v => {
                        if (v.label == layer.options.showType) {
                            v.layers.push({
                                size: layer.options.size || "image",
                                isVisible:
                                    layer.options.opacity === 0 ? false : true,
                                showName: layer.options.showName,
                                showType: layer.options.showType
                            });
                        }
                    });
                }
            });
            this.extendContral();
        },
        // 合并图层控制 已加载的地图图层通过遍历获得 初始化未加载的图层通过传参
        extendContral() {
            let layer = [
                {
                    label: "底图",
                    name: "1",
                    layers: [
                        {
                            size: "image",
                            isVisible: false,
                            showName: "底图",
                            showType: "底图"
                        },
                        {
                            size: "image",
                            isVisible: true,
                            showName: "底图注记",
                            showType: "底图"
                        },
                        {
                            size: "Vectortile",
                            isVisible: false,
                            showName: "蓝色底图",
                            showType: "底图"
                        }
                    ]
                },
                {
                    label: "专题图",
                    name: "2",
                    layers: [
                        {
                            size: "Vectortile",
                            isVisible: false,
                            showName: "城墙结构示意图",
                            showType: "专题图"
                        },
                        {
                            size: "Vectortile",
                            isVisible: false,
                            showName: "城墙旅游公共服务体系现状图",
                            showType: "专题图"
                        },
                        {
                            size: "Vectortile",
                            isVisible: false,
                            showName: "城墙旅游总体现状图",
                            showType: "专题图"
                        },
                        {
                            size: "Vectortile",
                            isVisible: false,
                            showName: "旅游资源评价图",
                            showType: "专题图"
                        },
                        {
                            size: "Vectortile",
                            isVisible: false,
                            showName: "城墙墙体分类图",
                            showType: "专题图"
                        },
                        {
                            size: "Vectortile",
                            isVisible: false,
                            showName: "保护区划图",
                            showType: "专题图"
                        },
                        {
                            size: "Vectortile",
                            isVisible: false,
                            showName: "病害分布图",
                            showType: "专题图"
                        },
                        {
                            size: "Vectortile",
                            isVisible: true,
                            showName: "遗产要素分布图",
                            showType: "专题图"
                        }
                    ]
                }
            ];
            let nameList = [
                ...new Set([
                    ...this.layerControl.map(v => v.label),
                    ...layer.map(v => v.label)
                ])
            ];
            this.layerData = [];
            nameList.forEach(v => {
                let layerListMap = this.layerControl.filter(
                    item => item.label == v
                )[0];
                let layerListControl = layer.filter(item => item.label == v)[0];
                if (layerListMap && layerListControl) {
                    let layerItem = {
                        label: v,
                        name: layerListMap.name,
                        layers: []
                    };
                    let name = layerListMap.layers.map(val => val.showName);
                    layerItem.layers = layerListMap.layers;
                    layerListControl.layers.forEach(item => {
                        if (!name.includes(item.showName)) {
                            layerItem.layers.push(item);
                        }
                    });
                    this.layerData.push(layerItem);
                } else {
                    this.layerData.push(layerListMap || layerListControl);
                }
            });
        }
    }
};
</script>
<style>
.mapboxgl-map {
    z-index: 9999;
}
</style>

<style scoped>
.location_popover {
    position: absolute;
    right: 45px;
    top: 60px;
    width: 300px;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);
    z-index: 999;
}

.location_popover .content {
    text-align: left;
    min-height: 150px;
}

.location_popover .content .item {
    height: 25px;
    clear: both;
}

.location_popover .content .item img {
    margin: 0px 10px;
    cursor: pointer;
    float: left;
}

.location_popover .content .item div {
    margin: 3px 0px;
    float: left;
    font-size: 12px;
}

.location_popover .content .btn {
    float: right;
    margin: 10px;
}

.location_popover .content .fpfont {
    margin: 0px 10px;
}

.location_popover .content .fpfont span {
    margin-right: 10px;
    font-size: 13px;
    color: black;
}

.location_popover .content .fpcontent {
    height: 115px;
    margin: 0px 10px;
}

.location_popover .content .fpcontent .part1 {
    color: black;
    font-size: 13px;
    float: left;
    height: 100%;
    margin-right: 10px;
}
.header {
    background: #4285f4;
    height: 30px;
    line-height: 30px;
    font-size: 13px;
    text-align: left;
    color: #fff;
    font-weight: bold;
    padding: 0px 10px;
}

.header i {
    position: absolute;
    right: 10px;
    top: 9px;
    font-size: 12px;
}
</style>